import isEmpty from "lodash/isEmpty";
import * as Yup from "yup";

const signupFormValidationSchema = Yup.object({
  userType: Yup.string().required("User Type is required"),
  businessType: Yup.string().when("userType", {
    is: "Service Provider",
    then: (schema) => schema.required("Business Type is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  mobileNumber: Yup.string()
    .matches(/^(?:\+91|91|0)?[123456789]\d{9}$/, "Phone number is not valid")
    .required("Please enter a phone number."),
  serviceId: Yup.string().when("userType", {
    is: "Service Provider",
    then: (schema) => schema.required("Please select a service."),
    otherwise: (schema) => schema.notRequired(),
  }),
  name: Yup.string()
    .matches(/^[A-Za-z]+(?:[A-Za-z\s]+)*$/, "Name is not valid")
    .min(2)
    .max(32)
    .required("Please enter full name."),
  businessName: Yup.string().when("businessType", {
    is: "Business",
    then: (schema) =>
      schema
        .matches(/^[A-Za-z]+(?:[A-Za-z\s]+)*$/, "Business Name is not valid")
        .min(2)
        .max(64)
        .required("Please enter business name."),
  }),
  experience: Yup.number().when("userType", {
    is: "Service Provider",
    then: (schema) =>
      schema
        .required("Please enter an experience value.")
        .when("businessType", {
          is: "Business",
          then: (schema) =>
            schema
              .min(0, "Experience must be at least 0 year.")
              .max(1000, "Experience must be at most 1000 years."),
          otherwise: (schema) =>
            schema
              .min(0, "Experience must be at least 0 year.")
              .max(100, "Experience must be at most 100 years."),
        }),
    otherwise: (schema) => schema.notRequired(),
  }),

  isEighteenOrOlder: Yup.boolean().when("userType", {
    is: "Service Provider",
    then: (schema) =>
      schema.oneOf([true], "You must confirm you are 18 years or older."),
    otherwise: (schema) => schema.oneOf([false]),
  }),
  isAutoApproved: Yup.boolean(),
  otp: Yup.array()
    .of(Yup.string().matches(/^\d$/, "Must be a 4 digit"))
    .length(4, "OTP must be 4 digit")
    .required("Please enter a 4-digit OTP."),
  serviceOrCurrentLocationObject: Yup.object().when("userType", {
    is: "Service Provider",
    then: (schema) => schema.required("Please enter service location."),
    otherwise: (schema) => schema.required("Please enter current location."),
  }),
  homeLocationObject: Yup.object().when("userType", {
    is: "Service Provider",
    then: (schema) => schema.required("Please enter home location."),
    otherwise: (schema) => schema.required("Please enter home location."),
  }),
});

const isDisableForSubmit = (formik) => {
  return (
    isEmpty(formik?.values?.homeLocationObject) ||
    isEmpty(formik?.values?.serviceOrCurrentLocationObject) ||
    (formik?.values?.userType === "Service Provider" &&
      Object.keys(formik.errors)?.some((key) =>
        ["isAutoApproved", "isEighteenOrOlder"]?.includes(key)
      ))
  );
};

// Function to check if a form is disabled for user's first step
// const isDisableButtonForUserFirstStep = (formik, isRegistered) => {
//   return (
//     isEmpty(formik.values.mobileNumber) ||
//     isRegistered ||
//     isEmpty(formik.values.name) ||
//     Object.keys(formik.errors).some((key) =>
//       ["mobileNumber", "name"].includes(key)
//     )
//   );
// };

// Function to check if a form is disabled for user submission
// const isDisableForUserSubmit = (formik) => {
//   return (
//     isEmpty(formik.values.homeLocationObject) ||
//     isEmpty(formik.values.serviceOrCurrentLocationObject) ||
//     Object.keys(formik.errors).some((key) =>
//       [
//         "mobileNumber",
//         "name",
//         "homeLocationObject",
//         "serviceOrCurrentLocationObject",
//       ].includes(key)
//     )
//   );
// };

// Function to check if a form is disabled for provider's first step
// const isDisableButtonForProviderFirstStep = (formik, isRegistered) => {
//   return (
//     isEmpty(formik.values.mobileNumber) ||
//     isRegistered ||
//     isEmpty(formik.values.name) ||
//     (formik.values.businessType === "Business" &&
//       isEmpty(formik.values.businessName)) ||
//     Object.keys(formik.errors).some((key) =>
//       [
//         "serviceId",
//         "mobileNumber",
//         "businessType",
//         "businessName",
//         "name",
//         "experience",
//       ].includes(key)
//     )
//   );
// };

// Function to check if a form is disabled for provider submission
// const isDisableForProviderSubmit = (formik) => {
//   return (
//     isEmpty(formik.values.serviceOrCurrentLocationObject) ||
//     isEmpty(formik.values.homeLocationObject) ||
//     Object.keys(formik.errors).some((key) =>
//       [
//         "serviceId",
//         "mobileNumber",
//         "businessType",
//         "businessName",
//         "name",
//         "experience",
//         "serviceOrCurrentLocationObject",
//         "homeLocationObject",
//         "isAutoApproved",
//         "isEighteenOrOlder",
//       ].includes(key)
//     )
//   );
// };

export {
  isDisableForSubmit,
  // isDisableButtonForProviderFirstStep,
  // isDisableButtonForUserFirstStep,
  // isDisableForProviderSubmit,
  // isDisableForUserSubmit,
  signupFormValidationSchema,
};
