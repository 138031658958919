import { FormLabel, TextField } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { styles } from "./styleSheet";

const TextInput = ({
  name,
  label,
  placeholder,
  value,
  type,
  onChange,
  onBlur,
  error,
  helperText,
  onKeyDown,
  pattern,
  inputmode,
  maxLength,
}) => {
  return (
    <div style={styles.inputContainer}>
      <FormLabel style={styles.formLabel}>{label}</FormLabel>
      <TextField
        name={name}
        variant="outlined"
        type={type}
        placeholder={placeholder}
        fullWidth
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        error={error}
        helperText={helperText}
        onKeyDown={onKeyDown}
        autoComplete="off"
        pattern={pattern}
        inputmode={inputmode}
        inputProps={{ maxLength }}
        sx={styles.inputBoxStyle}
      />
    </div>
  );
};

TextInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  pattern: PropTypes.string,
  inputmode: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.any,
  onKeyDown: PropTypes.func,
  maxLength: PropTypes.number,
};

export default TextInput;
