import { useMediaQuery } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import Layout from "../../Layout";
import { ListItem, styles } from "./styleSheet";
function LegalDocumentsLayout({ data }) {
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  return (
    <Layout>
      <div style={styles.container}>
        <div className="container" style={styles.mainBox}>
          <div style={styles.headerTitle(isSmallScreen)}>{data[0]?.header}</div>
          <div style={styles.paraContainer}>
            {data[0]?.paragraphs?.map((para, index) => (
              <div style={styles.paraHead(isSmallScreen)} key={index}>
                {para}
              </div>
            ))}
            <ol style={styles.numberedList}>
              {data[0]?.sections?.map((section, sectionIndex) => (
                <ListItem key={sectionIndex}>
                  <strong>{section?.title}</strong>
                  {section?.paragraphs?.map((para, paraIndex) => (
                    <div style={styles.paraHead(isSmallScreen)} key={paraIndex}>
                      {para}
                    </div>
                  ))}
                  <ol style={styles.alphaList}>
                    {section?.content?.map((content, contentIndex) => (
                      <ListItem key={contentIndex}>{content}</ListItem>
                    ))}
                  </ol>
                </ListItem>
              ))}
            </ol>
          </div>
        </div>
      </div>
    </Layout>
  );
}
LegalDocumentsLayout.propTypes = {
  data: PropTypes.array.isRequired,
};
export default LegalDocumentsLayout;
