const servicesMainContainer = (isMediumScreen) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  gap: isMediumScreen ? "32px" : "64px",
  padding: isMediumScreen ? "24px 16px" : "64px 96px 0",
});
const popularServicesContainer = (isSmallScreen) => ({
  display: "flex",
  gap: isSmallScreen ? "24px" : "64px",
  flexDirection: "column",
  justifyContent: "start",
  textAlign: "start",
});
const popularServicesHeadingBox = { textAlign: "center" };
const popularServicesText = (isSmallScreen) => ({
  fontSize: isSmallScreen ? "32px" : "48px",
  lineHeight: isSmallScreen ? "30px" : "46px",
  fontWeight: "700",
  color: "#292929",
  margin: "0",
});
const allServicesText = (isSmallScreen) => ({
  fontSize: isSmallScreen ? "24px" : "40px",
  lineHeight: isSmallScreen ? "30px" : "50px",
  fontWeight: "600",
  color: "#14171F",
  margin: "0",
  textAlign: "start",
});
const horizonatalContainer = {
  display: "flex",
  overflowX: "auto",
  gap: "10px",
  padding: "10px",
  "&::-webkit-scrollbar": {
    display: "none",
  },
  scrollbarWidth: "none",
};

const popularServicesCard = (isSmallScreen) => ({
  maxWidth: "100%",
  borderRadius: "24px",
  display: "flex",
  gap: isSmallScreen ? "8px" : "16px",
  flexDirection: "column",
  width: "100%",
  boxShadow: "0 27px 41px 0 #0000008",
});
const allServicesCard = (isSmallScreen) => ({
  width: "100%",
  height: "100%",
  maxHeight: isSmallScreen ? "209px" : "315px",
  fontSize: isSmallScreen ? "14px" : "18px",
  lineHeight: isSmallScreen ? "18px" : "23px",
  fontWeight: "500",
  color: "#252525",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "24px",
  boxShadow: "0 27px 41px 0 #0000008",
  cursor: "pointer",
  transition: "background-color 0.3s ease",
  "&:hover": {
    backgroundColor: "#f0f0f0",
  },
});
const popularServicesImg = (isSmallScreen) => ({
  borderRadius: "16px",
  width: isSmallScreen ? "180px" : "288px",
  height: isSmallScreen ? "117px" : "200px",
  objectFit: "contain",
  alignSelf: "center",
  backgroundColor: "#BCDDFA",
});
const serviceCardItem = (isSmallScreen) => ({
  minWidth: isSmallScreen ? "180px" : "288px",
  marginRight: "16px",
});
const cardContentBox = (isSmallScreen) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "start",
  alignItems: "start",
  gap: isSmallScreen ? "4px" : "8px",
  textAlign: "start",
  height: "100%",
  minHeight: "80px",
  "&:last-child": {
    padding: isSmallScreen ? "0 8px 4px 8px" : "0 12px 8px 12px",
  },
});

const serviceName = (isSmallScreen) => ({
  fontSize: isSmallScreen ? "14px" : "18px",
  lineHeight: isSmallScreen ? "18px" : "23px",
  fontWeight: "500",
  color: "#252525",
  overflowWrap: "anywhere",
});
const serviceDesc = (isSmallScreen) => ({
  fontSize: isSmallScreen ? "12px" : "16px",
  lineHeight: isSmallScreen ? "15px" : "20px",
  fontWeight: "400",
  color: "#505050",
});
const allServicesContainer = (isMediumScreen) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  gap: isMediumScreen ? "8px" : "18px",
  padding: isMediumScreen ? "24px 28px" : "64px 96px",
});
const categoriesText = (isSmallScreen) => ({
  fontSize: isSmallScreen ? "18px" : "36px",
  lineHeight: isSmallScreen ? "22px" : "42px",
  fontWeight: "600",
  color: "#14171F",
  margin: "0",
});
const categoriesNameText = (isSmallScreen) => ({
  textAlign: "start",
  fontSize: isSmallScreen ? "22px" : "32px",
  fontWeight: "600",
  lineHeight: isSmallScreen ? "22px" : "40px",
  color: "#000000",
  marginBottom: isSmallScreen ? "32px" : "50px",
});
const horizontalLine = {
  height: "1px",
  backgroundColor: "#DFDFE1",
  width: "100%",
  marginTop: "12px",
  marginBottom: "24px",
};
const subCategoriesText = (isSmallScreen) => ({
  margin: "0",
  fontSize: isSmallScreen ? "18px" : "22px",
  fontWeight: "600",
  color: "#000000",
  textAlign: "start",
});
const servicesCardPaper = (isSmallScreen) => ({
  padding: isSmallScreen ? "8px 12px" : "12px 16px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  borderRadius: "16px",
  border: "1px solid #DFDFE1",
  boxShadow: "none !important",
});
const servicesIcon = (isSmallScreen) => ({
  width: isSmallScreen ? "36px" : "42px",
  height: isSmallScreen ? "36px" : "42px",
});
const serviceNameText = (isSmallScreen) => ({
  fontSize: isSmallScreen ? "14px" : "18px",
  lineHeight: "22px",
  color: "#000000",
  fontWeight: "400",
  textAlign: "start",
  overflowWrap: "anywhere",
});
const arrowIcon = (isSmallScreen) => ({
  backgroundColor: "#EBF4FF",
  borderRadius: "50%",
  padding: isSmallScreen ? "4px" : "8px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: isSmallScreen ? "8px" : "12px",
});
const notAvailableBox = {
  padding: "12px 16px",
  alignItems: "center",
  borderRadius: "16px",
  border: "1px solid #DFDFE1",
};
const serviceNameBox = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "16px",
};
const categoriesContainer = {
  display: "flex",
  flexWrap: "wrap",
  gap: "10px",
  marginTop: "10px",
  alignItems: "center",
  justifyContent: "start",
};
const categoryBox = (selectedCategoryId, isSmallScreen) => ({
  borderRadius: "20px",
  border: !selectedCategoryId ? "1px solid #000000" : "1px solid #0072dd",
  padding: "4px 12px",
  cursor: "pointer",
  fontSize: isSmallScreen ? "12px" : "16px",
  color: selectedCategoryId ? "#d9d9d9" : "#000000",
  backgroundColor: selectedCategoryId ? "#0072dd" : "transparent",
  WebkitTapHighlightColor: "transparent",
});
const allServicesTextBox = (isSmallScreen, isMediumScreen) => ({
  display: "flex",
  justifyContent: "space-between",
  flexDirection: isMediumScreen ? "column" : "row",
  gap: isSmallScreen ? "12px" : "28px",
});
const categoriesMainContainer = (isMediumScreen) => ({
  width: isMediumScreen ? "100%" : "50%",
});
const categoriesTextStyle = (isSmallScreen) => ({
  textAlign: "start",
  marginBottom: "8px",
  fontSize: isSmallScreen ? "14px" : "22px",
  lineHeight: isSmallScreen ? "18px" : "28px",
  fontWeight: "600",
  color: "#14171F",
});
const categoryImgBox = {
  display: "flex",
  alignItems: "center",
  gap: "14px",
};
const subcategoryImgBox = (isSmallScreen) => ({
  display: "flex",
  alignItems: "center",
  gap: "8px",
  marginLeft: "4px",
  marginBottom: isSmallScreen ? "4px" : "16px",
});
const categoryIcon = { width: "40px", height: "40px" };
const subcategoryIcon = { width: "28px", height: "28px" };
const displayCategoriesCont = (isSmallScreen) => ({
  marginTop: "24px",
  marginBottom: isSmallScreen ? "64px" : "108px",
});
const searchBar = {
  width: "100%",
  maxWidth: "450px",
  fontSize: "16px",
  borderRadius: "10px",
  position: "relative",
  marginTop: "12px",
};

const subCategoriesGrid = (isSmallScreen) => ({
  marginBottom: isSmallScreen ? "36px" : "64px",
});
const textFieldBox = (isMediumScreen) => ({
  width: isMediumScreen ? "100%" : "50%",
});
const textFieldStyle = { display: "flex" };
const searchFieldInnerStyle = {
  "& .MuiOutlinedInput-root": {
    borderRadius: "16px",
    fontFamily: "Sora",
  },
  "& .MuiOutlinedInput-input": {
    padding: "12.5px 14px",
  },
};
const clearIconStyle = {
  cursor: "pointer",
};
const orangeLine = (isSmallScreen) => ({
  width: "100%",
  height: "100%",
  maxWidth: isSmallScreen ? "185px" : "330px",
  maxHeight: isSmallScreen ? "8px" : "11px",
});
const viewAllServicesBox = {
  marginTop: "32px",
  textAlign: "center",
};
const viewAllServicesBtn = {
  fontSize: "16px",
  lineHeight: "20px",
  border: "1px solid #7A7A7A",
  borderRadius: "8px",
  color: "#292929",
  textTransform: "none",
  fontFamily: "Outfit",
  "&:hover": {
    backgroundColor: "#0072DD",
    color: "#FFFFFF",
    border: "1px solid #0072DD",
  },
};
const arrowButton = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#FFFFFF",
  position: "absolute",
  top: "40%",
  transform: "translateY(-50%)",
  backgroundColor: "#525252",
  zIndex: 1,
  width: "40px",
  height: "40px",
  borderRadius: "50%",
  "&:hover": {
    backgroundColor: "#525252",
    opacity: 0.9,
    color: "#FFFFFF",
  },
};
const arrowSvg = { fontSize: "16px" };
const horzMainContainer = { position: "relative" };
const categoriesSkeletonBox = {
  display: "flex",
  flexWrap: "wrap",
  gap: "10px",
};
export const styles = {
  servicesMainContainer,
  popularServicesContainer,
  popularServicesHeadingBox,
  horizonatalContainer,
  popularServicesText,
  popularServicesCard,
  popularServicesImg,
  allServicesCard,
  cardContentBox,
  serviceName,
  serviceDesc,
  serviceCardItem,
  allServicesContainer,
  categoriesText,
  categoriesNameText,
  horizontalLine,
  subCategoriesText,
  servicesCardPaper,
  servicesIcon,
  serviceNameText,
  arrowIcon,
  notAvailableBox,
  serviceNameBox,
  categoriesContainer,
  categoryBox,
  allServicesTextBox,
  categoriesMainContainer,
  categoriesTextStyle,
  categoryImgBox,
  subcategoryImgBox,
  categoryIcon,
  subcategoryIcon,
  displayCategoriesCont,
  searchBar,
  subCategoriesGrid,
  textFieldBox,
  textFieldStyle,
  searchFieldInnerStyle,
  clearIconStyle,
  orangeLine,
  viewAllServicesBox,
  allServicesText,
  viewAllServicesBtn,
  arrowButton,
  arrowSvg,
  horzMainContainer,
  categoriesSkeletonBox,
};
