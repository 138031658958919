import { useMediaQuery } from "@mui/material";
import React from "react";
import { WhatsAppImg } from "../../constant/ImagesS3";
import { ROUTES } from "../../constant/routes";
import { WhBtnStyles } from "./styleSheet";
const WhatsAppButton = () => {
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  return (
    <div style={WhBtnStyles.container}>
      <a
        href={ROUTES.WHATS_APP_URL}
        target="_blank"
        rel="noopener noreferrer"
        style={WhBtnStyles.link}
      >
        <img
          src={WhatsAppImg}
          alt="whats-app"
          style={WhBtnStyles.whatsAppImg(isSmallScreen)}
        />
      </a>
    </div>
  );
};

export default WhatsAppButton;
