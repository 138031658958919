import { ERROR_MESSAGES } from "../constant/messages";
import { ROUTES } from "../constant/routes";
import { fetchData } from "./api.service";

export const getFAQs = async () => {
  try {
    const url = `${ROUTES.FAQ}`;
    const res = await fetchData(url);
    return res;
  } catch (error) {
    console.error(ERROR_MESSAGES.fetchDataError, error);
    throw error;
  }
};
