import { Grid, Paper, Skeleton } from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { DefaultServicesIcon } from "../../constant/ImagesS3";
import { splitServiceNames } from "../../constant/utils";
import { styles } from "./styleSheet";

const ServiceCard = ({ service, isSmallScreen }) => {
  const [isImageLoading, setIsImageLoading] = useState(true);

  return (
    <Grid item xs={12} sm={6} md={4} lg={3} key={service?.serviceId}>
      <Paper elevation={3} sx={styles.servicesCardPaper(isSmallScreen)}>
        <div style={styles.serviceNameBox}>
          {isImageLoading && (
            <Skeleton
              variant="rectangular"
              width={isSmallScreen ? 36 : 42}
              height={isSmallScreen ? 36 : 42}
              sx={styles.servicesIcon(isSmallScreen)}
            />
          )}
          <img
            src={service?.serviceIcon || DefaultServicesIcon}
            alt={service?.serviceName}
            style={{
              ...styles.servicesIcon(isSmallScreen),
              display: isImageLoading ? "none" : "block",
            }}
            onLoad={() => setIsImageLoading(false)}
            onError={(e) => {
              e.target.src = DefaultServicesIcon;
              setIsImageLoading(false);
            }}
          />
          <div style={styles.serviceNameText(isSmallScreen)}>
            {splitServiceNames(service?.serviceName)}
          </div>
        </div>
      </Paper>
    </Grid>
  );
};

ServiceCard.propTypes = {
  service: PropTypes.shape({
    serviceId: PropTypes.string.isRequired,
    serviceIcon: PropTypes.string,
    serviceName: PropTypes.string,
  }).isRequired,
  isSmallScreen: PropTypes.bool.isRequired,
};

export default ServiceCard;
