import React, { useEffect } from "react";
import LegalDocumentsLayout from "../../components/LegalDocumentsLayout/LegalDocumentsLayout";
import { cookiesPolicyData } from "../../constant/staticData";

function CookiesPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return <LegalDocumentsLayout data={cookiesPolicyData} />;
}
export default CookiesPolicy;
