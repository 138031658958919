import React, { useEffect } from "react";
import LegalDocumentsLayout from "../../components/LegalDocumentsLayout/LegalDocumentsLayout";
import { disclaimerData } from "../../constant/staticData";

function Disclaimer() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return <LegalDocumentsLayout data={disclaimerData} />;
}

export default Disclaimer;
