import React from "react";
import { BataiyoLogo } from "../../constant/ImagesS3";
import { ROUTES } from "../../constant/routes";
import "./PageNotFound.css";
function PageNotFound() {
  return (
    <section className="page-not-found-container">
      <div className="page-not-found-img-container">
        <img src={BataiyoLogo} alt="bataiyo-logo" loading="lazy" />
      </div>
      <h6 className="not-found-first-heading">Looking for something?</h6>
      <p className="not-found-first-para">
        We're sorry. The Web address you entered is not a functioning page on
        our site.{" "}
      </p>
      <p className="not-found-second-para">
        {" "}
        Go to Bataiyo.com's{" "}
        <a href={ROUTES.HOME} style={{ color: "blue" }}>
          Home
        </a>{" "}
        Page
      </p>
    </section>
  );
}

export default PageNotFound;
