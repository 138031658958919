import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useMediaQuery } from "@mui/material";
import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { ROUTES } from "../../constant/routes";
import { blogsListData, blogsSEOData } from "../../constant/staticData";
import { getInitialChar } from "../../constant/utils.js";
import PageNotFound from "../../pages/PageNotFound/PageNotFound";
import Seo from "../SEO/Seo.js";
import { styles } from "./styleSheet";
function BlogPost() {
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const isMediumScreen = useMediaQuery("(max-width: 1000px)");
  const { id } = useParams();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const post = blogsListData?.find((post) => post.id === parseInt(id));
  if (!post) {
    return <PageNotFound />;
  }
  const blogsSeo = blogsSEOData?.find((b) => b.id === parseInt(id));

  return (
    <>
      <Seo
        title={`Bataiyo | ${blogsSeo.title}`}
        description={blogsSeo.description}
        keywords={blogsSeo.keywords}
        url={`blog/${blogsSeo.id}`}
      />
      <div
        className="flag-background-image"
        style={styles.viewBlogPostMainContainer(isMediumScreen)}
      >
        <div style={styles.viewBlogPostHeadingBox(isSmallScreen)}>
          <Link to={ROUTES.BLOG} style={styles.backToAllBlogsLink}>
            <ArrowBackIcon style={styles.backArrow} />
            Back to Blogs
          </Link>{" "}
          <div style={styles.viewBlogPostTag}>{post?.tags}</div>
          <h3 style={styles.viewBlogPostTitleText(isSmallScreen)}>
            {post?.title}
          </h3>
          <div style={styles.viewBlogPostAuthorDateBox(isSmallScreen)}>
            <div style={styles.viewBlogPostAuthorBox}>
              {" "}
              {post?.authorImage ? (
                <img
                  src={post?.authorImage}
                  alt="author"
                  style={styles.viewBlogPostAuthorImg}
                  loading="lazy"
                />
              ) : (
                getInitialChar(post?.authorName)
              )}
              <div style={styles.viewBlogPostAuthorNameText}>
                {post?.authorName}
              </div>
            </div>
            <div style={styles.viewBlogPostPublishDateText}>
              {post?.publishDate}
            </div>
          </div>
        </div>
        <div>
          <img
            src={post?.coverImage}
            alt="blog"
            style={styles.viewBlogPostCoverImg(isMediumScreen)}
            loading="lazy"
          />
        </div>
        <div style={styles.viewBlogPostDescContainer}>{post?.description}</div>
      </div>
    </>
  );
}

export default BlogPost;
