const listContainer = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "start",
  alignItems: "start",
  padding: "16px",
  gap: "34px",
  marginTop: "32px",
};
const listItomBox = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};
const listLastNumber = { alignSelf: "start", margin: "0 8px 0 28px" };
const listNumber = { margin: "0 8px 0 28px" };
const listLabel = {
  textAlign: "start",
  width: "100%",
  fontSize: "1rem",
  lineHeight: "1.5rem",
  fontWeight: "400",
  color: "#000000DE",
};
const noteBox = {
  display: "flex",
  alignItems: "baseline",
  justifyContent: "space-between",
  gap: "8px",
};
const noteDesc = {
  textAlign: "start",
  fontSize: "0.9rem",
  lineHeight: "1.5rem",
  fontWeight: "400",
  color: "#000000DE",
};
export const styles = {
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
  content: {
    flex: "1 0 auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: "auto",
    padding: "20px",
    maxWidth: "600px",
  },
  listContainer,
  listItomBox,
  listNumber,
  listLabel,
  listLastNumber,
  noteBox,
  noteDesc,
};
