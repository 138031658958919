import React from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import WhatsAppButton from "./components/common/WhatsAppButton";
import { ROUTES } from "./constant/routes";
import { ApiDataProvider } from "./context/ApiDataContext";
import { DialogProvider } from "./context/DialogContext";
import useScroll from "./hooks/useScroll";
import routes from "./routes/routes";

const App = () => {
  const { initialLoading } = useScroll();
  const location = useLocation();

  if (initialLoading) {
    return null;
  }
  const showWhatsAppButton = location.pathname !== "/app";
  // const recaptchaKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
  return (
    <div className="App" data-scroll-container>
      <ApiDataProvider>
        <DialogProvider>
          <GoogleReCaptchaProvider
            reCaptchaKey={ROUTES.RECAPTCHA_SITE_KEY}
            scriptProps={{
              async: false,
              defer: false,
              appendTo: "head",
              nonce: undefined,
              theme: "dark",
            }}
          >
            <Routes>
              {routes?.map((route) => (
                <Route
                  key={route?.id}
                  path={route?.path}
                  element={route?.element}
                />
              ))}
            </Routes>
            {showWhatsAppButton && <WhatsAppButton />}
          </GoogleReCaptchaProvider>
        </DialogProvider>
      </ApiDataProvider>
    </div>
  );
};

export default App;
