import { ERROR_MESSAGES } from "../constant/messages";
import { ROUTES } from "../constant/routes";
import { fetchData, postData } from "./api.service";

/* The function `getLocations` asynchronously fetches city locations using a specified route and handles any errors that occur during the process. */
export const getLocations = async (query) => {
  try {
    const url = `${ROUTES?.GET_LOCATION}${query}`;
    const res = await fetchData(url);
    return res;
  } catch (error) {
    console.error(ERROR_MESSAGES?.fetchDataError, error);
    throw error;
  }
};

/* The function `getServiceList` asynchronously fetches a list of services from a specified URL using `fetchData` and handles any errors that occur. */
export const getServiceList = async () => {
  try {
    const url = `${ROUTES?.SERVICES}`;
    const res = await fetchData(url);
    return res;
  } catch (error) {
    console.error(ERROR_MESSAGES?.fetchDataError, error);
    throw error;
  }
};

/* The function `getServiceListMap` asynchronously fetches service list data categories from a specified URL */
// export const getServiceListMap = async () => {
//   try {
//     const url = `${ROUTES?.SERVICESMAP}`;
//     const res = await fetchData(url);
//     return res;
//   } catch (error) {
//     console.error(ERROR_MESSAGES?.fetchDataError, error);
//     throw error;
//   }
// };

/* The function `checkUserAlreadyRegistered` asynchronously checks if a user is already registered by sending a POST request to a specified URL with user data.*/
export const checkUserAlreadyRegistered = async (userData) => {
  try {
    const url = `${ROUTES?.CHECK_USER_REGISTERED}`;
    const res = await postData(url, userData);
    return res;
  } catch (error) {
    console.error(ERROR_MESSAGES?.fetchDataError, error);
    throw error;
  }
};
/* The function `postSignUpFormData` sends a POST request with form data for signing up and returns the response. */
export const postSignUpFormData = async (initialRequestBody) => {
  try {
    const url = `${ROUTES?.SIGNUP_FORM}`;
    const res = await postData(url, initialRequestBody);
    return res;
  } catch (error) {
    console.error(ERROR_MESSAGES?.fetchDataError, error);
    throw error;
  }
};

/* The function `verifyOTP` sends an OTP (One Time Password) using a POST request and returns the response.*/
export const sendOTP = async (initialRequestBody) => {
  try {
    const url = `${ROUTES?.sendOTP}`;
    const res = await postData(url, initialRequestBody);
    return res;
  } catch (error) {
    console.error(ERROR_MESSAGES?.fetchDataError, error);
    throw error;
  }
};

export const fetchServerTimeApi = async () => {
  try {
    const url = `/healthcheck`;
    const res = await fetchData(url);
    return res;
  } catch (error) {
    console.error(ERROR_MESSAGES?.fetchDataError, error);
    throw error;
  }
};
