import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const DeviceRedirectHandler = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const userAgent = navigator.userAgent.toLowerCase();
    const isAndroid = userAgent.includes("android");
    const isIOS = /iphone|ipad|ipod/.test(userAgent);

    if (isAndroid) {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.bataiyo.mobile&pli=1";
    } else if (isIOS) {
      window.location.href =
        "https://apps.apple.com/in/app/bataiyo/id6504960016";
    } else {
      setLoading(false);
      navigate("/");
    }
  }, [navigate]);

  return (
    <>
      {loading && (
        <Box
          className="loader-overlay"
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
};

export default DeviceRedirectHandler;
