import { useEffect, useState } from "react";
import { ERROR_MESSAGES } from "../constant/messages";
import { isValidPhoneNumber } from "../constant/utils";
import { checkUserAlreadyRegistered } from "../services/form.service";

const useCheckUserRegistration = (phoneNumber, activeStep) => {
  const [checkPhoneError, setCheckPhoneError] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);
  const [loading, setLoading] = useState(false);

  /* This `useEffect` hook is responsible for checking the user registration status based on the provided phone number.*/
  useEffect(() => {
    const validatePhoneNumber = async () => {
      try {
        if (phoneNumber) {
          let normalizedPhoneNumber = phoneNumber?.replace(/\D/g, "");
          if (normalizedPhoneNumber?.length === 10) {
            normalizedPhoneNumber = `+91${normalizedPhoneNumber}`;
          }
          if (isValidPhoneNumber(normalizedPhoneNumber)) {
            setLoading(true);
            const payload = { mobileNumber: normalizedPhoneNumber };
            const response = await checkUserAlreadyRegistered(payload);
            setIsRegistered(response?.data?.result);
          }
        }
      } catch (error) {
        console.error(ERROR_MESSAGES?.fetchDataError, error);
        setCheckPhoneError(true);
      } finally {
        setLoading(false);
      }
    };
    if (activeStep === 1) {
      validatePhoneNumber();
    }
  }, [phoneNumber, activeStep]);

  return { isRegistered, checkPhoneError, loading };
};

export default useCheckUserRegistration;
