import PropTypes from "prop-types";
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";

const DialogContext = createContext();

export const useDialog = () => {
  return useContext(DialogContext);
};

/* The DialogProvider component manages the state of a dialog being open or closed and provides functions to open and close the dialog.*/
export const DialogProvider = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleDialogOpen = useCallback(() => {
    setIsDialogOpen(true);
  }, []);

  const handleDialogClose = useCallback(() => {
    setIsDialogOpen(false);
    if (location.pathname === "/register") {
      navigate("/");
    }
  }, [location.pathname, navigate]);

  // Use useMemo to memoize the context value
  const value = useMemo(
    () => ({
      isDialogOpen,
      handleDialogOpen,
      handleDialogClose,
    }),
    [isDialogOpen, handleDialogOpen, handleDialogClose]
  );

  return (
    <DialogContext.Provider value={value}>{children}</DialogContext.Provider>
  );
};

DialogProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
