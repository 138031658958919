import { useMediaQuery } from "@mui/material";
import React, { memo, useState } from "react";
import { HeroSectionImg, HeroSectionMobImg } from "../../constant/ImagesS3";
import { useDialog } from "../../context/DialogContext";
import RegistrationMarquee from "../HeroSection/RegistrationMarquee";
import CustomDialog from "../SignUpFormVerification/CustomDialog";
import "./HeroSection.css";
const HeroSection = () => {
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const { handleDialogOpen } = useDialog();
  const [handleSuccessDialogOpen, setHandleSuccessDialogOpen] = useState(false);
  return (
    <>
      <div className="hero-container" id="hero">
        <div>
          <RegistrationMarquee />
        </div>
        <section className="hero-section">
          <div className="hero-text-box">
            <h1 className="hero-heading">
              Your <span className="hero-blue-text">Recommendation</span>{" "}
              Matters!
            </h1>
            <div className="hero-subheading">
              Help the{" "}
              <span className="hero-green-text">service providers</span> you{" "}
              trust by <span className="hero-blue-text">recommending</span> them
              to your <span className="hero-orange-text">friends.</span>
            </div>
            <div className="hero-download-btn-box">
              <button
                className="hero-download-btn"
                onClick={() => setHandleSuccessDialogOpen(true)}
              >
                Download App
              </button>
            </div>{" "}
          </div>
          <div className="hero-img-box">
            <img
              src={isSmallScreen ? HeroSectionMobImg : HeroSectionImg}
              alt="group-img"
              className="hero-group-img"
            />
          </div>
        </section>
      </div>
      {handleDialogOpen && (
        <CustomDialog
          isDialogOpen={handleSuccessDialogOpen}
          handleClose={() => setHandleSuccessDialogOpen(false)}
        />
      )}
    </>
  );
};

export default memo(HeroSection);
