import { Box, Card, CardContent, Skeleton, useMediaQuery } from "@mui/material";
import React from "react";
import { styles } from "./styleSheet";

function LatestBlogSkeleton() {
  const isSmallScreen = useMediaQuery("(max-width: 600px)");

  return (
    <Card sx={styles.latestBlogCardSkeleton}>
      <Skeleton
        variant="rectangular"
        height={240}
        width="100%"
        sx={styles.latestBlogImage}
      />
      <CardContent sx={styles.cardContentBox}>
        <Box
          style={{
            ...styles.cardContainerInnerBox,
            width: "100%",
          }}
        >
          <Skeleton
            variant="square"
            height="100%"
            width="20%"
            sx={styles.cardTagsSkeleton}
          />
          <Box className="blog-title">
            <Skeleton variant="text" height="100%" width="100%" />
            <Skeleton variant="text" height="100%" width="90%" />
          </Box>
        </Box>
        <Box
          sx={{
            ...styles.topBlogCardAuthorDateBox(isSmallScreen),
            width: "100%",
          }}
        >
          <Box
            sx={{
              ...styles.topBlogAuthorImgBox(isSmallScreen),
              width: "100%",
            }}
          >
            <Skeleton
              variant="circular"
              height="100%"
              width="100%"
              style={styles.authorImgStyle}
            />
            <Skeleton
              variant="text"
              height="100%"
              width="50%"
              sx={styles.latestBlogAuthorName}
            />
            <Skeleton
              variant="text"
              height="100%"
              width="30%"
              sx={styles.latestBlogDateStyle}
            />
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

export default LatestBlogSkeleton;
