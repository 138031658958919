import ErrorIcon from "@mui/icons-material/Error";
import {
  FormControl,
  FormLabel,
  InputAdornment,
  TextField,
} from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { IndiaFlagLogo } from "../../constant/ImagesS3";
import { ERROR_MESSAGES } from "../../constant/messages";
import { styles } from "./styleSheet";
const PhoneNumberInput = ({
  name,
  label,
  placeholder,
  value,
  onChange,
  onBlur,
  error,
  helperText,
  isRegistered,
  loading,
  checkPhoneError,
}) => {
  /* The handleChange function that only numeric values are inputted and limits the input to 10 characters.*/
  const handleChange = (e) => {
    const { value } = e.target;
    const numericValue = value.replace(/\D/g, "").slice(0, 10);
    onChange({ target: { name, value: numericValue } });
  };

  return (
    <div style={styles.formControlWrapper}>
      <FormControl style={styles.inputContainer}>
        <FormLabel style={styles.formLabel}>{label}</FormLabel>
        <TextField
          name={name}
          variant="outlined"
          type="tel"
          placeholder={placeholder}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img
                  src={IndiaFlagLogo}
                  alt="flag"
                  style={styles.phoneNumFlagImage}
                  loading="lazy"
                />{" "}
                +91
              </InputAdornment>
            ),
            inputProps: { maxLength: 10 },
          }}
          value={value}
          onChange={handleChange}
          onBlur={onBlur}
          error={error}
          helperText={helperText}
          autoComplete="off"
          sx={styles.inputBoxStyle}
        />
      </FormControl>
      {!error && isRegistered && !loading && !checkPhoneError && (
        <div style={styles.errorMessagesStyle}>
          <ErrorIcon />
          {ERROR_MESSAGES?.userRegistred}
        </div>
      )}
    </div>
  );
};

PhoneNumberInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.any,
  isRegistered: PropTypes.bool,
  loading: PropTypes.bool,
  checkPhoneError: PropTypes.bool,
};

export default PhoneNumberInput;
