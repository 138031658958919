import Cookies from "js-cookie";
import {
  BlueArrowIcon,
  GreenArrowIcon,
  OrangeArrowIcon,
} from "../constant/ImagesS3";

export const getFAQSettings = (isUsersQue, isProvidersQue) => {
  let bgColor;
  let color;
  let arrow;
  let questions;
  let heading;

  switch (true) {
    case isUsersQue:
      heading = "For Users";
      bgColor = "#FFF7EF";
      color = "#FF820F";
      arrow = OrangeArrowIcon;
      break;
    case isProvidersQue:
      heading = "For Service Provider";
      bgColor = "#F4FFF8";
      color = "#06A445";
      arrow = GreenArrowIcon;
      break;
    default:
      heading = "Common Questions";
      bgColor = "#EFF3FF";
      color = "#0072DD";
      arrow = BlueArrowIcon;
      break;
  }

  return { bgColor, color, arrow, questions, heading };
};

/* The function `getCookieConsent` checks if the cookie consent has been accepted by retrieving the value of the "cookieConsent" cookie.*/
export const getCookieConsent = () => {
  return Cookies.get("cookieConsent") === "accepted";
};

/* The function `setCookieConsent` sets a cookie named "cookieConsent" with the value "accepted" that expires in 365 days.*/
export const setCookieConsent = () => {
  Cookies.set("cookieConsent", "accepted", { expires: 365 });
};

/* The `truncateString` function shortens a given string to a specified length and appends "..." if the string exceeds the specified length.
 * @param str - A string that want to truncate.
 * @param num - The `num` parameter in the `truncateString` function represents the maximum length of the string `str` after truncation.*/
export const truncateString = (str, num) => {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + "...";
};

/* The function `getInitialChar` takes a name as input, splits it into words, extracts the first character of each word, converts them to uppercase, and returns the initials, with a maximum length of 2 characters.
 * @param name - The `getInitialChar` function takes a `name` parameter as input. This parameter should be a string representing a person's name.*/
export const getInitialChar = (name) => {
  const names = name.split(" ");
  const initials = names.map((n) => n.charAt(0).toUpperCase()).join("");
  return initials.length > 2 ? initials.slice(0, 2) : initials;
};

/* The isValidPhoneNumber function checks if a given phone number is valid based on Indian phone number patterns.*/
export const isValidPhoneNumber = (number) => {
  const phoneNumberPattern = /^(?:\+91|91|0)?[123456789]\d{9}$/;
  return phoneNumberPattern.test(number);
};

/* The function `filterServicesOptions` filters an array of options based on a given input value, sorting the results alphabetically.*/
export const filterServicesOptions = (options, state) => {
  const inputValue = state.inputValue.toLowerCase();

  const startWithInputValue = options?.filter((option) => {
    const serviceName = option?.serviceName.toLowerCase();
    return serviceName.startsWith(inputValue);
  });

  const containInputValue = options?.filter((option) => {
    const serviceName = option?.serviceName.toLowerCase();
    return (
      !serviceName.startsWith(inputValue) && serviceName.includes(inputValue)
    );
  });

  const sortedStartWithInputValue = startWithInputValue.sort((a, b) =>
    a.serviceName.toLowerCase().localeCompare(b.serviceName.toLowerCase())
  );
  const sortedContainInputValue = containInputValue.sort((a, b) =>
    a.serviceName.toLowerCase().localeCompare(b.serviceName.toLowerCase())
  );

  return [...sortedStartWithInputValue, ...sortedContainInputValue];
};

/* The `filterCityOptions` function filters a list of options based on a given input value prioritizing options that start with the input value.*/
export const filterCityOptions = (options, inputValue) => {
  if (!inputValue) {
    // Sort alphabetically and return all options when input is empty
    return [...options].sort((a, b) => a.localeCompare(b));
  }

  const lowercasedInput = inputValue.toLowerCase();
  const startsWithMatches = options.filter((option) =>
    option.toLowerCase().startsWith(lowercasedInput)
  );
  const includesMatches = options.filter(
    (option) =>
      option.toLowerCase().includes(lowercasedInput) &&
      !startsWithMatches.includes(option)
  );
  return [...startsWithMatches, ...includesMatches];
};

/* The handleNameKeyDown function allows only letters, spaces, and specific control keys to be input in a text field. */
export const handleNameKeyDown = (event) => {
  const charCode = event.keyCode || event.which;
  // Allow control keys: backspace, delete, arrows, etc.
  const controlKeys = [8, 46, 37, 39]; // Backspace, Delete, Left Arrow, Right Arrow
  // Allow only letters, spaces, and control keys
  if (
    !/^[A-Za-z\s]$/.test(String.fromCharCode(charCode)) &&
    !controlKeys.includes(charCode)
  ) {
    event.preventDefault();
  }
};

/* The `capitalizeWords` function takes a string as input, converts it to lowercase, capitalizes the first letter of each word, and returns the modified string.*/
export const capitalizeWords = (str) => {
  if (!str) return "";
  return str
    .toLowerCase() // Convert to lower case to handle mixed case inputs
    .split(" ") // Split the string into words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
    .join(" "); // Join the words back into a single string
};

/* The debounce function delays the execution of a function until a specified amount of time has passed without the function being called again.*/
export const debounce = (func, delay) => {
  let timer;
  return (...args) => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
};

/* The function `splitServiceNames` takes a service name as input, formats it by capitalizing the first letter of each word and separating words with spaces, slashes, and hyphens, and then returns the formatted service name.*/
export const splitServiceNames = (serviceName) => {
  return serviceName
    ? serviceName
        .toLowerCase()
        .replace(/\s*\/\s*/g, "/")
        .split(" ")
        .map((segment) => {
          return segment
            .split("/")
            .map((slashSegment) => {
              return slashSegment
                .split("-")
                .map((word) => {
                  return word.length > 0
                    ? word[0].toUpperCase() + word.slice(1)
                    : "";
                })
                .join("-");
            })
            .join("/");
        })
        .join(" ")
    : "";
};

/* The function `capitalizeServicesWords` takes a string input and capitalizes the first letter of each word, while preserving slashes and hyphens. */
export function capitalizeServicesWords(input) {
  if (!input) {
    return "";
  }
  return input
    .toLowerCase()
    .split(" ")
    .map((segment) => {
      return segment
        .split("/")
        .map((slashSegment) => {
          return slashSegment
            .split("-")
            .map((word) => {
              if (word.length > 0) {
                return word[0].toUpperCase() + word.slice(1);
              } else {
                return "";
              }
            })
            .join("-");
        })
        .join("/");
    })
    .join(" ");
}
