import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Grid, TextField, useMediaQuery } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import React, { useCallback, useEffect, useState } from "react";
import { ERROR_MESSAGES } from "../../constant/messages";
import { servicesStaticData } from "../../constant/servicesStaticData";
import { capitalizeWords, filterServicesOptions } from "../../constant/utils";
import { useApiData } from "../../context/ApiDataContext";
import AllServicesSkeleton from "./AllServicesSkeleton";
import CategoryList from "./CategoryList";
import ServiceCard from "./ServiceCard";
import { styles } from "./styleSheet";

function AllServices() {
  const { serviceList } = useApiData();
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const isMediumScreen = useMediaQuery("(max-width: 1024px)");
  const [categories, setCategories] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState("all");
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchFocused, setSearchFocused] = useState(false);
  const [shimmerEffect, setShimmerEffect] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShimmerEffect(false);
    }, 1500);

    return () => clearTimeout(timer);
  }, []);

  const displaySearchServiceData = serviceList
    ?.map((service) => ({
      ...service,
      serviceName: capitalizeWords(service?.serviceName),
    }))
    ?.sort((a, b) => a.serviceName.localeCompare(b.serviceName));

  const fetchCategoriesAndServices = useCallback(async () => {
    const mapServices = (services, categoryId, subcategoryId) =>
      services?.map((service) => ({
        ...service,
        categoryId,
        subcategoryId,
      }));

    const mapSubcategories = (subcategories, categoryId) =>
      subcategories?.map((subcategory) => ({
        subcategoryId: subcategory?.subcategoryId,
        subcategoryName: subcategory?.subcategoryName,
        subcategoryImage: subcategory?.subcategoryImage,
        services: mapServices(
          subcategory?.services,
          categoryId,
          subcategory?.subcategoryId
        ),
      }));

    const mapCategories = (categories) =>
      categories?.map((category) => ({
        categoryId: category?.categoryId,
        categoryName: category?.categoryName,
        categoryImage: category?.categoryImage,
        subcategories: mapSubcategories(
          category?.subcategories,
          category?.categoryId
        ),
      }));

    setLoading(true);
    try {
      const fetchedCategories = mapCategories(servicesStaticData);
      setCategories(fetchedCategories);
    } catch (error) {
      console.error(ERROR_MESSAGES.fetchDataError, error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchCategoriesAndServices();
  }, [fetchCategoriesAndServices]);

  const handleCategoryChange = (id) => {
    setSelectedCategoryId(id);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  const handleSearchFocus = () => {
    setSearchFocused(true);
  };

  const handleSearchBlur = () => {
    if (!searchQuery) setSearchFocused(false);
  };

  const filterServices = (services, query) => {
    if (!query) return services;
    const filtered = filterServicesOptions(services, {
      inputValue: query,
    });
    return Array.from(
      new Map(
        filtered?.map((service) => [service._id || service.serviceId, service])
      ).values()
    );
  };

  const selectedCategory = categories?.find(
    (category) => category?.categoryId === selectedCategoryId
  );

  const displayedCategories =
    selectedCategoryId === "all" ? categories : [selectedCategory];

  const renderServiceList = () => {
    let servicesToDisplay = [];
    if (searchFocused || searchQuery) {
      servicesToDisplay = filterServices(displaySearchServiceData, searchQuery);
    }

    if (servicesToDisplay?.length > 0) {
      return (
        <Grid container spacing={2}>
          {servicesToDisplay?.map((service) => (
            <ServiceCard
              key={service?._id || service?.serviceId}
              service={service}
              isSmallScreen={isSmallScreen}
            />
          ))}
        </Grid>
      );
    } else if (searchQuery) {
      return (
        <Grid container spacing={2}>
          <Grid item xs={12} style={styles.notAvailableBox}>
            Oops! No services match your search.
          </Grid>
        </Grid>
      );
    } else {
      return (
        <CategoryList
          displayedCategories={displayedCategories}
          searchFocused={searchFocused}
          filterServices={filterServices}
          isSmallScreen={isSmallScreen}
          searchQuery={searchQuery}
        />
      );
    }
  };

  return (
    <div style={styles.allServicesContainer(isMediumScreen)}>
      <div>
        {loading || shimmerEffect ? (
          <AllServicesSkeleton />
        ) : (
          <>
            <div
              style={styles.allServicesTextBox(isSmallScreen, isMediumScreen)}
            >
              <div style={styles.textFieldBox(isMediumScreen)}>
                <h3 style={styles.allServicesText(isSmallScreen)}>
                  All Services
                </h3>
                <div style={styles.textFieldStyle}>
                  <TextField
                    sx={styles.searchFieldInnerStyle}
                    variant="outlined"
                    placeholder="Search services here..."
                    value={searchQuery}
                    onChange={handleSearchChange}
                    style={styles.searchBar}
                    onFocus={handleSearchFocus}
                    onBlur={handleSearchBlur}
                    fullWidth
                    autoComplete="off"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {searchFocused ? (
                            <ClearIcon
                              style={styles.clearIconStyle}
                              onClick={() => {
                                setSearchQuery("");
                                setSearchFocused(false);
                              }}
                            />
                          ) : (
                            <SearchIcon />
                          )}
                        </InputAdornment>
                      ),
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Escape") {
                        setSearchQuery("");
                        setSearchFocused(false);
                      }
                    }}
                  />
                </div>
              </div>
              {!searchFocused && !searchQuery && (
                <div style={styles.categoriesMainContainer(isMediumScreen)}>
                  <div style={styles.categoriesTextStyle(isSmallScreen)}>
                    Categories
                  </div>
                  <div style={styles.categoriesContainer}>
                    <Box
                      sx={styles.categoryBox(
                        selectedCategoryId === "all",
                        isSmallScreen
                      )}
                      onClick={() => handleCategoryChange("all")}
                    >
                      All Services
                    </Box>
                    {categories?.map((category) => (
                      <Box
                        key={category?.categoryId}
                        sx={styles.categoryBox(
                          selectedCategoryId === category?.categoryId,
                          isSmallScreen
                        )}
                        onClick={() =>
                          handleCategoryChange(category?.categoryId)
                        }
                      >
                        {capitalizeWords(category?.categoryName)}
                      </Box>
                    ))}{" "}
                    {!searchFocused && !searchQuery && (
                      <CategoryList
                        categories={categories}
                        selectedCategoryId={selectedCategoryId}
                        handleCategoryChange={handleCategoryChange}
                        isSmallScreen={isSmallScreen}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
            <div style={styles.horizontalLine} />
            {renderServiceList()}
          </>
        )}
      </div>
    </div>
  );
}

export default AllServices;
