import React, { useEffect } from "react";
import LegalDocumentsLayout from "../../components/LegalDocumentsLayout/LegalDocumentsLayout";
import { privacyPolicyData } from "../../constant/staticData";

function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return <LegalDocumentsLayout data={privacyPolicyData} />;
}

export default PrivacyPolicy;
