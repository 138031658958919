import React from "react";
import {
  ServiceProvidersImg,
  ServiceSeekersImg,
} from "../../constant/ImagesS3";
import {
  servicesExperiences,
  usersExperiences,
} from "../../constant/staticData";
import "./ForService.css";

function ForService() {
  return (
    <section className="for-service-main">
      <div className="for-service-content-box">
        <div />
        <div className="first-heading-box">
          <h2 className="heading-second">
            <span className="second-heading-color-text">User’s</span> Experience
          </h2>
        </div>
      </div>
      <div className="service-seekers-content">
        <img
          src={ServiceSeekersImg}
          alt="service seekers"
          className="service-seekers-image"
          loading="lazy"
        />{" "}
        <div className="services-list">
          {usersExperiences?.map((item) => (
            <div className="services-options" key={item?.id}>
              {" "}
              <div className="icon-container">
                <img src={item?.img} alt={item?.alt} loading="lazy" />{" "}
              </div>
              <div>
                <div className="service-list-heading">{item?.title}</div>
                <div className="service-list-description">
                  {item?.description}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <section className="service-seekers-main">
        <h2 className="heading-second">
          <span className="second-exp-heading-color-text">Service’s</span>{" "}
          Experience
        </h2>
        <div className="service-provider-content">
          <div className="services-provider-list">
            {servicesExperiences?.map((item) => (
              <div className="services-options" key={item?.id}>
                <div className="icon-container">
                  <img src={item?.img} alt={item?.alt} loading="lazy" />{" "}
                </div>
                <div>
                  <div className="service-list-heading">{item?.title}</div>
                  <div className="service-list-description">
                    {item?.description}
                  </div>
                </div>
              </div>
            ))}
          </div>{" "}
          <img
            src={ServiceProvidersImg}
            alt="service seekers"
            className="service-provider-image"
            loading="lazy"
          />
        </div>
      </section>{" "}
    </section>
  );
}

export default ForService;
