import React from "react";
import "../../App.css";
import PopularServices from "../../components/AllServices/PopularServices.jsx";
import AppFeature from "../../components/AppFeatures/AppFeature.jsx";
import FAQSection from "../../components/FAQSection/FAQSection";
import ForService from "../../components/ForService/ForService";
import HeroSection from "../../components/HeroSection/HeroSection";
import LogosSection from "../../components/LogosSection/LogosSection.jsx";
import Roadmap from "../../components/Roadmap/Roadmap.jsx";
import SixDegreeSeperationSection from "../../components/SixDegreeSeperationSection/SixDegreeSeperationSection";
import TheBataiyoAdvantage from "../../components/TheBataiyoAdvantage/TheBataiyoAdvantage";
import Layout from "../../Layout.js";
function LandingPage() {
  return (
    <Layout>
      <HeroSection />
      <LogosSection />
      <div className="flag-background-image">
        <AppFeature />
        <TheBataiyoAdvantage />
        <PopularServices />
        <SixDegreeSeperationSection />
      </div>
      <ForService />
      <Roadmap />
      <FAQSection />
    </Layout>
  );
}

export default LandingPage;
