import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  IconButton,
  Skeleton,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { OrangeLine } from "../../constant/ImagesS3";
import { ROUTES } from "../../constant/routes";
import { splitServiceNames, truncateString } from "../../constant/utils";
import { useApiData } from "../../context/ApiDataContext";
import ScrollDots from "./ScrollDots";
import { styles } from "./styleSheet";

function PopularServices() {
  const { serviceList } = useApiData();
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const isMediumScreen = useMediaQuery("(max-width: 1024px)");
  const [activeIndex, setActiveIndex] = useState(0);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);
  const [loading, setLoading] = useState(true);
  const containerRef = useRef(null);
  const navigate = useNavigate();
  // Filter services based on popularServiceSortOrder
  const filteredServices = serviceList
    ?.filter((service) => service.popular)
    ?.sort((a, b) => a.popularServiceSortOrder - b.popularServiceSortOrder);
  const servicesLoading = !filteredServices || filteredServices?.length === 0;

  // Simulate loading for 1.5 second
  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 1500);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current) {
        const container = containerRef.current;
        const cardWidth = container.children[0]?.getBoundingClientRect().width;
        const containerWidth = container.getBoundingClientRect()?.width;
        const scrollLeft = container.scrollLeft;
        const scrollableWidth = container.scrollWidth - containerWidth;

        const cardIndex = Math.floor(scrollLeft / cardWidth);
        const totalDots = 3;
        let dotIndex = cardIndex % totalDots;
        if (scrollLeft >= scrollableWidth) {
          dotIndex = totalDots - 1;
        }
        setActiveIndex(dotIndex);
        // Update arrow visibility based on scroll position
        setShowLeftArrow(scrollLeft > 0);
        setShowRightArrow(scrollLeft < scrollableWidth);
      }
    };

    const container = containerRef.current;
    container.addEventListener("scroll", handleScroll);
    return () => container.removeEventListener("scroll", handleScroll);
  }, [filteredServices]);

  // Scroll functions for arrows
  const scrollLeft = () => {
    if (containerRef?.current) {
      const cardWidth =
        containerRef?.current?.children[0]?.getBoundingClientRect()?.width;
      containerRef?.current?.scrollBy({ left: -cardWidth, behavior: "smooth" });
    }
  };

  const scrollRight = () => {
    if (containerRef?.current) {
      const cardWidth =
        containerRef?.current?.children[0]?.getBoundingClientRect()?.width;
      containerRef?.current?.scrollBy({ left: cardWidth, behavior: "smooth" });
    }
  };

  return (
    <div
      style={styles.servicesMainContainer(isMediumScreen)}
      id="popular-services"
    >
      {/* Popular Services Section */}
      <div style={styles.popularServicesContainer(isSmallScreen)}>
        <div style={styles.popularServicesHeadingBox}>
          <h1 style={styles.popularServicesText(isSmallScreen)}>
            Popular Services
          </h1>
          <img
            src={OrangeLine}
            alt="orange-line"
            style={styles.orangeLine(isSmallScreen)}
            loading="lazy"
          />
        </div>
        {/* Container for horizontal scroll */}
        <Box sx={styles.horzMainContainer}>
          {!isSmallScreen && !isMediumScreen && showLeftArrow && (
            <IconButton
              onClick={scrollLeft}
              sx={{
                ...styles.arrowButton,
                left: -60,
                transform: "translateY(-50%) rotate(180deg)",
              }}
            >
              <ArrowForwardIosIcon sx={styles.arrowSvg} />
            </IconButton>
          )}
          <Box ref={containerRef} sx={styles.horizonatalContainer}>
            {loading || servicesLoading
              ? // Render skeletons during loading
                Array.from({ length: 10 }).map((_, index) => (
                  <Box key={index} sx={styles.serviceCardItem(isSmallScreen)}>
                    <Box sx={styles.popularServicesCard(isSmallScreen)}>
                      <Skeleton
                        variant="square"
                        width={isSmallScreen ? "180px" : "288px"}
                        height={isSmallScreen ? "117px" : "200px"}
                        sx={styles.popularServicesCard(isSmallScreen)}
                      />
                      <Box>
                        <Skeleton
                          width="60%"
                          sx={styles.serviceName(isSmallScreen)}
                        />
                        <Skeleton
                          width="80%"
                          sx={styles.serviceDesc(isSmallScreen)}
                        />
                      </Box>
                    </Box>
                  </Box>
                ))
              : // Render actual services when loading is complete
                [
                  ...(Array.isArray(filteredServices)
                    ? filteredServices.map((service) => (
                        <Box
                          key={
                            service?._id
                              ? service?._id
                              : `service-${Math.random()}`
                          }
                          sx={styles.serviceCardItem(isSmallScreen)}
                        >
                          <Card sx={styles.popularServicesCard(isSmallScreen)}>
                            <CardMedia
                              component="img"
                              alt={service?.serviceName}
                              sx={styles.popularServicesImg(isSmallScreen)}
                              image={service?.serviceImage}
                            />
                            <CardContent
                              sx={styles.cardContentBox(isSmallScreen)}
                            >
                              <Box sx={styles.serviceName(isSmallScreen)}>
                                {splitServiceNames(service?.serviceName)}
                              </Box>
                              <Box sx={styles.serviceDesc(isSmallScreen)}>
                                {truncateString(service?.description, 60)}
                              </Box>
                            </CardContent>
                          </Card>
                        </Box>
                      ))
                    : []),
                  // Add the "View All" card
                  <Box
                    key="view-all-card"
                    sx={styles.serviceCardItem(isSmallScreen)}
                    onClick={() => navigate(ROUTES.SERVICES)}
                  >
                    <Card sx={styles.allServicesCard(isSmallScreen)}>
                      View All
                    </Card>
                  </Box>,
                ]}
          </Box>

          {!isSmallScreen && !isMediumScreen && showRightArrow && (
            <IconButton
              onClick={scrollRight}
              sx={{
                ...styles.arrowButton,
                right: -60,
              }}
            >
              <ArrowForwardIosIcon sx={styles.arrowSvg} />
            </IconButton>
          )}
          <ScrollDots isSmallScreen={isSmallScreen} activeIndex={activeIndex} />
          <div style={styles.viewAllServicesBox}>
            <Button
              component={Link}
              to={ROUTES.SERVICES}
              sx={styles.viewAllServicesBtn}
              variant="outlined"
            >
              View All
            </Button>
          </div>
        </Box>
      </div>
    </div>
  );
}

export default PopularServices;
