import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

export const containerStyle = (bgColor) => ({
  width: "100%",
  backgroundColor: bgColor,
  borderRadius: "24px",
  paddingBottom: "32px",
});

export const Heading = styled(Typography)(({ color }) => ({
  marginBottom: "20px",
  textAlign: "start",
  padding: "32px 32px 0 32px",
  fontWeight: "600",
  fontSize: "32px",
  lineHeight: "40px",
  color: color,
  "@media (max-width: 767px)": {
    fontSize: "28px",
    lineHeight: "36px",
  },
  "@media (max-width: 600px)": {
    fontSize: "24px",
    lineHeight: "36px",
  },
}));

export const CustomAccordion = styled(Accordion)(({ bgColor, isLast }) => ({
  margin: "0",
  boxShadow: "none",
  borderBottom: isLast ? "none" : "1px solid #DFDFE1",
  backgroundColor: bgColor,
  "&.Mui-expanded": {
    margin: "0",
  },
  "&.MuiAccordion-root:last-of-type": {
    borderBottom: "none",
  },
}));

export const CustomAccordionSummary = styled(AccordionSummary)(() => ({
  padding: "16px 32px",
  fontWeight: "400",
  fontSize: "24px",
  lineHeight: "30px",
  borderRadius: "0",
  borderBottom: "none",
  textAlign: "start",
  color: "#292929",
  "&.Mui-expanded": {
    borderBottom: "none",
    padding: "0 32px",
  },
  "@media (max-width: 767px)": {
    fontSize: "18px",
    lineHeight: "24px",
  },
  "@media (max-width: 600px)": {
    fontSize: "18px",
    lineHeight: "22px",
  },
}));
export const CustomAccordionDetails = styled(AccordionDetails)({
  padding: "0 32px 16px 32px",
  fontWeight: "400",
  fontSize: "18px",
  lineHeight: "22px",
  textAlign: "start",
  color: "#525252",
  "@media (max-width: 767px)": {
    fontSize: "16px",
    lineHeight: "24px",
  },
  "@media (max-width: 600px)": {
    fontSize: "16px",
    lineHeight: "18px",
  },
});

export const ArrowIconImg = styled("img")(({ expanded }) => ({
  transform: expanded ? "rotate(0deg)" : "rotate(360deg)",
}));
export const TabsStyle = {
  "& .MuiTab-root": {
    textTransform: "none",
    padding: "8px 32px",
    borderRadius: "24px",
    color: "#292929",
    fontSize: "18px",
    fontWeight: 500,
    backgroundColor: "#f5f5f5",
    fontFamily: "Sora",
    "@media (max-width: 960px)": {
      padding: "0 8px",
      margin: "0 4px",
      fontSize: "14px",
      minHeight: "34px",
    },
  },
  "& .Mui-selected": {
    backgroundColor: "#000000",
    color: "#fff !important",
    fontWeight: 500,
    fontSize: "18px",
    fontFamily: "Sora",
    "@media (max-width: 960px)": {
      padding: "0 8px",
      fontSize: "14px",
    },
  },
};
