import PropTypes from "prop-types";
import React, { useState } from "react";
import "./AcceptCookiesBanner.css";
function AcceptCookiesBanner({ onAccept }) {
  const [isVisible, setIsVisible] = useState(true);

  const handleAccept = () => {
    onAccept();
    setIsVisible(false);
  };

  const handleDismiss = () => {
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <>
      {isVisible && (
        <div className="banner-container">
          <p className="cookies-heading-text">We value your privacy</p>
          <p className="cookies-desc-text">
            We use cookies to enhance your browsing experience, serve
            personalized ads or content, and analyze our traffic.
          </p>
          <div className="banner-button-box">
            <button onClick={handleDismiss} className="banner-button">
              Reject
            </button>{" "}
            <button className="banner-accept-button" onClick={handleAccept}>
              Accept
            </button>
          </div>
        </div>
      )}
    </>
  );
}
AcceptCookiesBanner.propTypes = {
  onAccept: PropTypes.func,
};

export default AcceptCookiesBanner;
