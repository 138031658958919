import { styled } from "@mui/material/styles";

const container = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};
const mainBox = { maxWidth: "1024px", padding: "20px" };

const headerTitle = (isSmallScreen) => ({
  textAlign: "center",
  fontSize: isSmallScreen ? "32px" : "48px",
  lineHeight: 1.08349,
  fontWeight: 600,
  margin: isSmallScreen ? "20px 0 10px" : "30px 0 20px",
});

const paraHead = (isSmallScreen) => ({
  color: "#000",
  fontSize: isSmallScreen ? "18px" : "20px",
  marginBottom: isSmallScreen ? "0.5rem" : "1rem",
});

export const ListItem = styled("li")(({ theme }) => ({
  paddingLeft: 0,
  color: "#000",
  fontSize: "20px",
  marginBottom: "16px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "18px",
    marginBottom: "12px",
  },
}));

const numberedList = {
  listStyleType: "decimal",
  marginBottom: 0,
};

const alphaList = {
  listStyleType: "lower-alpha",
  marginBottom: 0,
};

export const styles = {
  container,
  mainBox,
  headerTitle,
  paraContainer: {
    textAlign: "start",
  },
  paraHead,
  numberedList,
  alphaList,
  listItemNo: {
    paddingTop: "0.5rem",
    paddingLeft: 0,
    color: "#000",
    fontSize: "20px",
  },
};
