import React, { useEffect } from "react";
import LegalDocumentsLayout from "../../components/LegalDocumentsLayout/LegalDocumentsLayout";
import { termsAndConditionsData } from "../../constant/staticData";

function TermsAndConditions() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {" "}
      <LegalDocumentsLayout data={termsAndConditionsData} />
    </>
  );
}

export default TermsAndConditions;
