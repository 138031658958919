import { Button, Dialog, DialogActions } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { useDialog } from "../../context/DialogContext.js";
import { styles } from "./styleSheet.js";

function AlertDialog({ isDialogOpen, handleClose, message, image }) {
  const { handleDialogClose } = useDialog();
  const handleModalClose = () => {
    handleClose();
    handleDialogClose();
  };
  if (!isDialogOpen) return null;
  return (
    <Dialog
      open={isDialogOpen}
      onClose={handleModalClose}
      maxWidth="sm"
      fullWidth
      sx={styles.CustomAlertDialogBox}
    >
      <div style={styles.CustomDialogContent}>
        <img
          src={image}
          alt="success"
          style={styles.CustomDialogAlertImage}
          loading="lazy"
        />
        <div style={styles.CustomDialogAlertText}>{message}</div>{" "}
      </div>
      <DialogActions sx={styles.CloseButtonBox}>
        <Button onClick={handleModalClose} sx={styles.CloseButtonStyle}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

AlertDialog.propTypes = {
  isDialogOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  message: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default AlertDialog;
