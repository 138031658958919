import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useMediaQuery } from "@mui/material";
import PropTypes from "prop-types";
import React, { memo } from "react";
import { ProviderImg, UserImg } from "../../constant/ImagesS3";
import { styles } from "./styleSheet";

function SelectUserType({ formik, onNextStep }) {
  const isSmallScreen = useMediaQuery("(max-width: 600px)");

  const handleUserTypeChange = (type) => {
    formik.setFieldValue("userType", type);
    onNextStep();
  };
  return (
    <div style={styles.userTypeContainer}>
      <div style={styles.userTypeHeading}>How will you use bataiyo?</div>
      <div
        style={styles.userTypeBox(isSmallScreen)}
        onClick={() => handleUserTypeChange("User")}
      >
        <img src={UserImg} alt="user" style={styles.userTypeImg} />
        <div style={styles.userTypeText(isSmallScreen)}>User</div>
        <div style={styles.userTypeArrowBox}>
          <ArrowForwardIcon sixe={16} />
        </div>
      </div>
      <div
        style={styles.providerTypeBox(isSmallScreen)}
        onClick={() => handleUserTypeChange("Service Provider")}
      >
        <img src={ProviderImg} alt="provider" style={styles.userTypeImg} />
        <div style={styles.userTypeText(isSmallScreen)}>Service Provider</div>
        <div style={styles.providerTypeArrowBox}>
          <ArrowForwardIcon sixe={16} />
        </div>
      </div>{" "}
    </div>
  );
}

SelectUserType.propTypes = {
  formik: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired,
  }).isRequired,
  onNextStep: PropTypes.func,
};

export default memo(SelectUserType);
