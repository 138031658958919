import { useMediaQuery } from "@mui/material";
import React from "react";
import {
  AppFeatureImg,
  AppFeatureMobImg,
  featureCrossImg,
} from "../../constant/ImagesS3";
import { featuresSectionData } from "../../constant/staticData";
import "./AppFeature.css";
function AppFeature() {
  const isSmallScreen = useMediaQuery("(max-width: 600px)");

  return (
    <div className="features-main-container" id="bataiyo-app">
      <div className="features-img-box">
        <img
          src={isSmallScreen ? AppFeatureMobImg : AppFeatureImg}
          alt="bataiyo-app-features"
          className="features-img"
        />
      </div>
      <div className="features-text-box">
        <div className="cross-icon-box">
          <img src={featureCrossImg} alt="cross-img" className="cross-icon" />
        </div>
        <div className="features-heading-box">
          <div className="features-heading-first">Featuring</div>
          <div className="features-heading-second">Bataiyo App</div>
        </div>
        <div className="features-description">
          {featuresSectionData?.description?.map((item, index) => (
            <p key={index}>{item}</p>
          ))}{" "}
        </div>
      </div>
    </div>
  );
}

export default AppFeature;
