import React from "react";
import { Link } from "react-router-dom";
import { BataiyoFooterLogo } from "../../constant/ImagesS3";
import { ROUTES } from "../../constant/routes";
import { socialMediaIcons } from "../../constant/staticData";
import "./Footer.css";

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <footer className="footer">
      <div className="footer-first-row">
        <div>
          <Link to="/" onClick={scrollToTop}>
            <img
              src={BataiyoFooterLogo}
              alt="bataiyo-logo"
              className="bataiyo-logo"
              loading="lazy"
            />
          </Link>
          <div className="footer-subheading">
            Your Trusted Services, Your Trusted Network{" "}
          </div>{" "}
        </div>
        <div className="links-container">
          <div className="links">
            {/* <Link to={ROUTES.ABOUT}>About</Link>
            <Link to={ROUTES.CONTACT}>Contact</Link>
            <Link to={ROUTES.SUBSCRIBE}>Subscribe</Link>
            <a href={`#${ROUTES.FAQ}`}>FAQ's</a> */}
          </div>
          <div className="social-icons">
            {socialMediaIcons.map((item) => {
              const IconComponent = item.icon;
              return (
                <a
                  key={item.id}
                  href={item.path}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label={item.alt}
                >
                  <IconComponent />
                </a>
              );
            })}
          </div>
        </div>
      </div>
      <div className="footer-second-row">
        <div className="copyright">
          Copyright © 2024 Bgent Solutions Private Limited. All rights reserved{" "}
        </div>
        <div className="privacy">
          <Link to={ROUTES.PRIVACY_POLICY}>Privacy Policy</Link>
          <Link to={ROUTES.TERMS}>Terms</Link>
          <Link to={ROUTES.COOKIES_POLICY}>Cookies Policy</Link>
          <Link to={ROUTES.DISCLAIMER}>Disclaimer</Link>
          <Link to={ROUTES.DELETE_ACCOUNT}>Delete Account</Link>
          {/* <Link to={ROUTES.BLOG}>Blog</Link> */}
          {/* <Link to={ROUTES.CHANGE_LANGUAGE}>
            <div className="global-en">
              <img src={Global} alt="global" />
              <div>En</div>
            </div>
          </Link> */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
