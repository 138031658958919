import { Delete, NoAccounts, Settings } from "@mui/icons-material";
import { Box, Container, Paper, Typography } from "@mui/material";
import React, { useEffect } from "react";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import { styles } from "./styleSheet";
function DeleteAccount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div style={styles.root} className="layout-main-box ">
      <Navbar />
      <Container style={styles.content}>
        <Paper elevation={3}>
          <Box padding={3}>
            <Typography variant="h4" component="h1" gutterBottom>
              Delete Account
            </Typography>
            <Typography variant="body1" gutterBottom>
              Follow these steps to delete your account:
            </Typography>

            <Box sx={styles.listContainer}>
              <Box sx={styles.listItomBox}>
                <Box>
                  <Settings />
                </Box>
                <Box sx={styles.listNumber}>1.</Box>
                <Box sx={styles.listLabel}>Go to Support in the Settings</Box>
              </Box>
              <Box sx={styles.listItomBox}>
                <Box>
                  <Delete />
                </Box>
                <Box sx={styles.listNumber}>2.</Box>
                <Box sx={styles.listLabel}>Click the Delete button</Box>
              </Box>
              <Box sx={styles.listItomBox}>
                <Box>
                  <NoAccounts />
                </Box>
                <Box sx={styles.listNumber}>3.</Box>
                <Box sx={styles.listLabel}>
                  Choose "Delete" to delete the account
                </Box>
              </Box>
            </Box>
            <Box sx={styles.noteBox}>
              <Box>
                <strong>Note:</strong>{" "}
              </Box>
              <Box sx={styles.noteDesc}>
                Your account will be permanently deleted one week after
                initiating the deletion process.
              </Box>
            </Box>
          </Box>
        </Paper>
      </Container>
      <Footer />
    </div>
  );
}

export default DeleteAccount;
