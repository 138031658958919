import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { styles } from "./styleSheet";

const RadioButtonGroup = ({ label, name, options, value, onChange }) => {
  return (
    <FormControl style={styles.radioContainer}>
      <FormLabel style={styles.formLabel}>{label}</FormLabel>
      <RadioGroup
        value={value}
        name={name}
        onChange={onChange}
        sx={styles.RadioGroupBox}
      >
        {options?.map((option) => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={<Radio />}
            label={option?.label}
            sx={styles.radioOptionsLabel}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

RadioButtonGroup.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default RadioButtonGroup;
