import React from "react";
import BlogPost from "../../components/Blog/BlogPost";
import Layout from "../../Layout";

function ViewBlogPost() {
  return (
    <Layout>
      <BlogPost />
    </Layout>
  );
}

export default ViewBlogPost;
