const blogMainContainer = (isMediumScreen) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  gap: isMediumScreen ? "32px" : "64px",
  padding: isMediumScreen ? "16px" : "64px 96px",
});
const topBlogCardAuthorDateBox = (isSmallScreen) => ({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "space-between",
  alignItems: "center",
  gap: isSmallScreen ? "10px" : "12px",
});
const topBlogAuthorImgBox = (isSmallScreen) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: isSmallScreen ? "12px" : "18px",
  color: "#292929",
  fontSize: "12px",
});
const authorImgStyle = { height: "24px", width: "24px" };
const latestBlogContainer = {
  display: "flex",
  gap: "32px",
  flexDirection: "column",
  justifyContent: "center",
  textAlign: "center",
  marginBottom: "40px",
};
const latestBlogText = (isSmallScreen) => ({
  fontSize: isSmallScreen ? "32px" : "48px",
  lineHeight: isSmallScreen ? "48px" : "60px",
  fontWeight: "700",
  color: "#292929",
  margin: "0",
});
const latestBlogCard = {
  maxWidth: "100%",
  padding: "16px",
  border: "1px solid #CCE4FF",
  borderRadius: "12px",
  display: "flex",
  gap: "12px",
  flexDirection: "column",
  boxShadow: "0px -4px 8px 0px #41607C14, 0px 4px 4px 0px #0000000A",
};
const latestBlogLink = { textDecoration: "none" };
const latestBlogGridBox = {
  display: "flex",
  justifyContent: "start",
  alignItems: "start",
};
const latestBlogImage = {
  borderRadius: "8px",
  height: "100%",
};
const cardContentBox = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "start",
  gap: "12px",
  padding: "8px",
  paddingBottom: "0 !important",
};
const cardContentTags = {
  backgroundColor: "#EBF4FF",
  fontSize: "12px",
  fontWeight: "500",
  lineHeight: "15px",
  padding: "4px 10px",
  borderRadius: "6px",
  color: "#0072DD",
  width: "fit-content",
};

const latestBlogAuthorName = {
  width: "100%",
  height: "auto",
  fontSize: "12px",
  fontWeight: "400",
  lineHeight: "15px",
  color: "#7A7A7A",
};
const latestBlogDateStyle = {
  fontSize: "12px",
  fontWeight: "500",
  lineHeight: "15px",
  color: "#525252",
};
const latestBlogHeadingBox = (isSmallScreen) => ({
  display: "flex",
  flexDirection: isSmallScreen ? "column-reverse" : "row",
  gap: "20px",
  alignItems: "center",
  justifyContent: "start",
});
const viewAllBlogsButton = {
  border: "1px solid #000000",
  color: "#000000",
  padding: "8px 16px",
  fontSize: "16px",
  fontWeight: "500",
  lineHeight: "20px",
  borderRadius: "100px",
  backgroundColor: "transparent",
};
const viewBlogPostMainContainer = (isMediumScreen) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  gap: "32px",
  padding: isMediumScreen ? "16px" : "32px 96px 32px 96px",
});
const viewBlogPostCoverImg = (isMediumScreen) => ({
  width: "100%",
  height: isMediumScreen ? "100%" : "60vh",
  borderRadius: "12px",
  objectFit: "contain",
  objectPosition: "center",
});
const viewBlogPostHeadingBox = (isSmallScreen) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "start",
  gap: isSmallScreen ? "10px" : "20px",
});
const backToAllBlogsLink = {
  display: "flex",
  alignItems: "center",
  textDecoration: "none",
  color: "#000",
};
const backArrow = {
  marginRight: "5px",
};
const viewBlogPostTag = {
  padding: "6px 12px",
  borderRadius: "6px",
  backgroundColor: "#0072DD",
  color: "#FFFFFF",
  fontSize: "14px",
  lineHeight: "20px",
  fontWeight: "500",
};
const viewBlogPostTitleText = (isSmallScreen) => ({
  margin: "0",
  color: "#292929",
  fontSize: isSmallScreen ? "24px" : "32px",
  fontWeight: "600",
  lineHeight: isSmallScreen ? "28px" : "40px",
  textAlign: "start",
  width: "100%",
  height: "auto",
});
const viewBlogPostAuthorDateBox = (isSmallScreen) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: isSmallScreen ? "10px" : "24px",
});
const viewBlogPostAuthorBox = {
  display: "flex",
  alignItems: "center",
  gap: "8px",
  justifyContent: "space-between",
};
const viewBlogPostAuthorImg = { height: "28px", width: "28px" };
const viewBlogPostAuthorNameText = {
  fontSize: "14px",
  fontWeight: "500",
  lineHeight: "20px",
  color: "#525252",
};
const viewBlogPostPublishDateText = {
  fontSize: "14px",
  fontWeight: "400",
  lineHeight: "20px",
  color: "#525252",
};
const viewBlogPostDescContainer = { textAlign: "start" };
const cardContainerInnerBox = {
  textAlign: "start",
  display: "flex",
  gap: "8px",
  flexDirection: "column",
};
const latestBlogCardSkeleton = {
  width: "100%",
  maxWidth: "100%",
  padding: "16px",
  borderRadius: "12px",
  display: "flex",
  gap: "12px",
  flexDirection: "column",
};
const cardTagsSkeleton = {
  fontSize: "12px",
  fontWeight: "500",
  lineHeight: "15px",
  padding: "4px 10px",
  borderRadius: "6px",
  color: "#0072DD",
  width: "fit-content",
};
export const styles = {
  blogMainContainer,
  topBlogCardAuthorDateBox,
  topBlogAuthorImgBox,
  authorImgStyle,
  latestBlogContainer,
  latestBlogText,
  latestBlogGridBox,
  latestBlogCard,
  latestBlogLink,
  latestBlogImage,
  cardContentBox,
  cardContentTags,
  latestBlogAuthorName,
  latestBlogDateStyle,
  latestBlogHeadingBox,
  viewAllBlogsButton,
  viewBlogPostMainContainer,
  viewBlogPostHeadingBox,
  backToAllBlogsLink,
  backArrow,
  viewBlogPostTag,
  viewBlogPostCoverImg,
  viewBlogPostTitleText,
  viewBlogPostAuthorDateBox,
  viewBlogPostAuthorBox,
  viewBlogPostAuthorImg,
  viewBlogPostAuthorNameText,
  viewBlogPostPublishDateText,
  viewBlogPostDescContainer,
  cardContainerInnerBox,
  latestBlogCardSkeleton,
  cardTagsSkeleton,
};
