import { useEffect, useState } from "react";

function useScroll() {
  const [initialLoading, setInitialLoading] = useState(true);

  /* This `useEffect` hook is performing several tasks related to scrolling behavior and attribute manipulation in a React component.*/
  useEffect(() => {
    let scroll;
    import("locomotive-scroll").then((locomotiveModule) => {
      scroll = new locomotiveModule.default({
        el: document.querySelector("[data-scroll-container]"),
        smooth: true,
        smoothMobile: false,
        resetNativeScroll: true,
      });
    });
    // Function to add the attribute to the element
    const addAttribute = () => {
      const popover = document.querySelector(".MuiDialog-container");
      const selectorPopover = document.querySelector(".MuiAutocomplete-popper");
      const navDraver = document.querySelector(".MuiDrawer-root");
      if (popover) {
        popover.setAttribute("data-lenis-prevent", "");
      }
      if (selectorPopover) {
        selectorPopover.setAttribute("data-lenis-prevent", "");
      }
      if (navDraver) {
        navDraver.setAttribute("data-lenis-prevent", "");
      }
    };

    // Create a MutationObserver instance
    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === "childList") {
          addAttribute();
        }
      }
    });

    // Start observing the body for changes
    observer.observe(document.body, { childList: true, subtree: true });
    setTimeout(() => {
      setInitialLoading(false);
    }, 300);

    // Cleanup
    return () => {
      if (scroll) scroll.destroy();
      observer.disconnect();
    };
  }, []);

  return { initialLoading };
}

export default useScroll;
