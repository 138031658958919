import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  useMediaQuery,
} from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { styles } from "./styleSheet";

const ConfirmationCheckboxes = ({
  isEighteenOrOlder,
  isAutoApproved,
  handleEighteenOrOlderChange,
  handleAutoApprovalChange,
  error,
  helperText,
  eighteenOrOlder,
}) => {
  const isSmallScreen = useMediaQuery("(max-width: 600px)");

  return (
    <FormControl component="fieldset" variant="standard">
      <FormGroup sx={styles.confirmationBox(isSmallScreen)}>
        {/* <FormControlLabel
          sx={styles.confirmationLabel}
          control={
            <Checkbox
              checked={isAutoApproved}
              onChange={handleAutoApprovalChange}
            />
          }
          label="I confirm my consent for automatic approval of phone requests."
        /> */}
        <div>
          <FormControlLabel
            sx={styles.confirmationLabel}
            control={
              <Checkbox
                checked={isEighteenOrOlder}
                onChange={handleEighteenOrOlderChange}
              />
            }
            label={
              "I confirm that I am 18 years or older and consent to the terms and conditions.*"
            }
          />
          {error && eighteenOrOlder && (
            <div className="location-errors">{helperText}</div>
          )}
        </div>
      </FormGroup>
    </FormControl>
  );
};

ConfirmationCheckboxes.propTypes = {
  isEighteenOrOlder: PropTypes.bool,
  isAutoApproved: PropTypes.bool,
  handleEighteenOrOlderChange: PropTypes.func,
  handleAutoApprovalChange: PropTypes.func,
  eighteenOrOlder: PropTypes.bool,
  error: PropTypes.string,
  helperText: PropTypes.string,
};

export default ConfirmationCheckboxes;
